// Publications.js
import React from 'react';
import { Header } from './Header';
import { Navigation } from './Navigation';

const Publications = () => {
    return(
        <>
            <Header />
            <Navigation />
            <section class="container mt-0">
            <div class="row">
                <div class="col-md-12">
                    <h2 class="text-center display-4 my-4">Publications</h2><hr class="my-4"/>
                    
                </div>
            </div>
            </section>
        </>
    );
};

export {Publications};