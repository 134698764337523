// Summary.js
import React from 'react';

const Summary = () => {
    return(
        <section class="container mt-0">
        <div class="row">
            <div class="col-md-12">
            <h2 class="text-center display-4 my-4">Summary</h2><hr class="my-4"/>
            <p>
                I am a dedicated Computer Science PhD candidate specializing in Generative
                Modelling, Computational Intelligence, and Critical Infrastructure 
                Protection.
            </p>
            <p>
                Currently, I hold the position of course convener for Computer Science 1A
                and 1B at the undergraduate level. In these modules, I guide students 
                through the intricacies of algorithm design, data structures, and object 
                orientation, leveraging the powerful C++ programming language.
            </p>
            <p>
                At the Honours level, I teach Advanced Artificial Intelligence. This 
                course empowers students to delve into advanced AI topics, culminating 
                in a comprehensive research endeavour and the development of a 
                proof-of-concept prototype. Moreover, it serves as a bridge, gently 
                ushering students into the realm of Master's level research.
            </p>
            <p>
                My role extends to facilitating the research component of a Critical 
                Infrastructure Protection course at the Honours level. Here, students 
                explore the dynamic landscape of Cybersecurity in Critical Infrastructure 
                Systems, delving into cutting-edge advancements. The research component 
                of the course covers topics such as safeguarding Smart Cities, 
                Industrial IoT, Industry 4.0, Smart Farming, Smart Manufacturing, and 
                Smart Grids.
            </p>
            <p>
                Beyond teaching, I am actively engaged in supervising Honours and 
                co-supervising Master's research projects focused on AI and 
                Cybersecurity. This mentoring role allows me to contribute to the growth 
                and development of aspiring researchers.
            </p>
            <p>
                Additionally, I serve as a technical committee member and reviewer for an 
                international peer-reviewed conference on Computational Intelligence and 
                Intelligent Systems. The conference publishes accepted papers through 
                the Association for Computing Machinery (ACM). Outside academics, I find
                joy in being a music enthusiast.
            </p>
            </div>
        </div>
        </section>
    );
};

export {Summary};